import Vue from 'vue'

import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import axios from 'axios'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate"
import uk from "vee-validate/dist/locale/uk.json"
import * as rules from "vee-validate/dist/rules"
//тут надо бы импортить то, что нужно

import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  //componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  //...,                // ...other defaults
});


Vue.prototype.$axios = axios;

//import VueAxios from 'vue-axios'
//window.axios = require('axios');
import VueToasted from 'vue-toasted'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
//import { createPopper } from '@popperjs/core';
//import PortalVue from 'portal-vue'

import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/main.css'
import './css/media_xs.css'
//import './css/media_sm.css'
//import './css/media_md.css'
//import './css/media_lg.css'
//import './css/media_xl.css'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("uk", uk);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

/*
const PasswordisCorrect = (value) => {
  return axios.post('/', { "action":"pschk", "ps": value })
    .then((response) => {
      return {
        valid: response.data.valid,
        data: {
            message: response.data.message
        }
      };
    });
};


extend('PasswordisCorrect', {
  validate: PasswordisCorrect,
  getMessage: field => field + ' невірний'
});
*/

/*
Validator.extend('verify_password', {
  getMessage: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
  validate: value => {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      return strongRegex.test(value);
  }
});
*/


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
//Vue.use(axios)
//Vue.use(VueAxios, axios)
Vue.use(VueToasted)
Vue.use(VueLodash, {name:'custom', lodash: lodash})
//Vue.use(PortalVue)
//Vue.use(createPopper)
Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер',
    "П'ятниця", 'Субота'
  ],
  dayOfWeekNamesShort: [
    'Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'
  ],
  monthNames: [
    'січня', 'лютого', 'березня', 'квітня', 'травня', 'червня',
    'липня', 'серпня', 'вересня', 'ховтня', 'листопада', 'грудня'
  ],
  monthNamesShort: [
    'Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер',
    'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'
  ],
  // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
  //timezone: 120
})

Vue.config.productionTip = false

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('uk-UA', {
      style: 'currency',
      currency: 'UAH',
      currencyDisplay: 'narrowSymbol'
  });
  return formatter.format(value);
});

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  var upper = []
  value.split(" ").forEach((word) => {
    upper.push(word.toUpperCase())
  })
  return upper.join(" ")
})

new Vue({
  render: h => h(App),
}).$mount('#app')
  