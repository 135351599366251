<template>
  <div id="appc">
    <b-navbar type="dark" variant="bruvis" toggleable="lg" fixed="top" class="py-0 px-0" v-if="salepoints">
      <b-container>
        <b-navbar-brand v-b-modal.debug v-if="logo">
          <b-img :src="logo.img" height="40" class="mx-1"></b-img><span class="d-none d-md-inline">{{logo.text}}</span>
        </b-navbar-brand>

        <!--b-navbar-nav>
          <b-nav-item class="text-white" small>
            <span class="d-block d-sm-none text-white">xs</span>
            <span class="d-none d-sm-block d-md-none text-white">sm</span>
            <span class="d-none d-md-block d-lg-none text-white">md</span>
            <span class="d-none d-lg-block d-xl-none text-white">lg</span>
            <span class="d-none d-xl-block text-white">xl</span>
          </b-nav-item> 
        </b-navbar-nav-->
        <b-navbar-nav class="ml-auto" style="flex-direction: row;">
          
          <!--b-button 
            variant="info" class="py-1 m-1 mx-0" 
            @click="getorders" 
            v-if="Object.keys(orders).length > 0 || Object.keys(checks).length > 0"
          >
            <b-icon icon="credit-card" animation="fade" variant="" v-b-popover.hover.bottomleft="'Є неоплачені замовлення!'"></b-icon>
          </b-button-->

          <!--b-nav-item 
            v-show="cart_count > 0" 
            class="py-1 m-1 button-cart text-white" 
            variant="white"
            @click="$bvModal.show('basket-modal2')" 
            :disabled="cart_count == 0"
          >
            <span style="font-size: small;" v-show="cart_sum > 0">{{ cart_sum }} <span>&#8372;</span>&nbsp;</span> 
            <b-icon icon="cart4"></b-icon>
            <div class="quant" id="header-cart-quantity" v-show="cart_count > 0">{{ cart_count }}</div> 
          </b-nav-item-->

          <b-nav-item
            class="py-0 m-1 mx-0" 
            @click="getorders" 
            v-if="Object.keys(zakazy).length > 0"
          >
            <b-button 
              class="btn btn-victor"
              v-b-popover.hover.bottomleft.v-victor="{ 
                customClass: 'bg-victor text-black font-weight-bold popover', 
                content :'Є неоплачені замовлення!'
              }"
            >
            <b-icon 
              icon="credit-card" animation="fade" 
            />
            </b-button>
          </b-nav-item>

          <b-nav-item 
            v-show="cart_count > 0"
            v-b-popover.hover.bottomleft.v-victor="{ 
              customClass: 'bg-victor text-black font-weight-bold popover', 
              content :'Вміст кошика при зміні дня та часу - не зберігається!'
            }"
            id="cart-button" 
            class="py-1 m-1 button-cart text-white" 
            variant="white"
            @click="$bvModal.show('basket-modal2')" 
            :disabled="cart_count == 0"
          >
            <b-icon icon="cart4" class="mr-2"></b-icon>
            <div class="quant" id="header-cart-quantity" v-show="cart_count > 0">{{ cart_count }}</div> 
            <span style="font-size: small;" v-show="cart_sum > 0">{{ cart_sum }} <span>&#8372;</span>&nbsp;</span> 
          </b-nav-item>

          <b-nav-item 
            v-if="clubcards && clubcards.length > 0" 
            v-b-popover.hover.bottomleft.v-victor="{ 
              customClass: 'bg-victor text-black font-weight-bold popover', 
              content :((clubcards[0]).balance < 0 && (clubcards[0]).cardlimit > 0)?'Доступний кредит: '+ ((clubcards[0]).cardlimit + (clubcards[0]).balance) + ' грн':'Баланс'
            }"
            class="py-1 m-1 button-cart text-white" 
          >
            <b-icon icon="piggy-bank-fill" flip-h class="mr-1" :variant="((clubcards[0]).balance > 0)?'light':'warning'"></b-icon>
            <span style="font-size: small;">{{ (clubcards[0]).balance }} <span>&#8372;</span>&nbsp;</span> 
            
          </b-nav-item>



          <!--b-button 
            v-show="cart_count > 0" 
            variant="info" class="py-1 m-1 button-cart" 
            @click="$bvModal.show('basket-modal2')" 
            :disabled="cart_count == 0"
          >
            <span style="font-size: small;" v-show="cart_sum > 0">{{ cart_sum }} <span>&#8372;</span>&nbsp;</span> 
            <b-icon icon="cart4"></b-icon>
            <div class="quant" id="header-cart-quantity" v-show="cart_count > 0">{{ cart_count }}</div> 
          </b-button-->
              
          <b-dropdown v-show="!needlogin" right variant="bruvis" class="p-0 pr-2 m-0">
            <template #button-content>
              <b-icon icon="person-fill"></b-icon><span class="d-none d-sm-inline p-0 m-0"> {{username}}</span>
            </template>
            <b-dropdown-item v-b-modal.userprofile-modal>
              <b-icon icon="person-badge" scale="1" class="mr-1"></b-icon>
              Профіль користувача
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.zakazy-payed-modal>
              <b-icon icon="bag-check" scale="1" class="mr-1"></b-icon>
              Оплачені замовлення
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.archive-modal>
              <b-icon icon="box-seam" scale="1" class="mr-1"></b-icon>
              Архів замовлень
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="#" @click="logout">
              <b-iconstack font-scale="1" class="mr-1">
                <b-icon stacked icon="door-open" variant="dark"></b-icon>
                <b-icon stacked icon="arrow-left-short" scale="1" shift-v="0" shift-h="-8"></b-icon>
              </b-iconstack>
              Вихід
            </b-dropdown-item>
          </b-dropdown>

        </b-navbar-nav>  
      </b-container>
    </b-navbar>      
   
    <b-overlay
      :show="globalbusy && !needlogin"
      rounded
      variant="white"
      blur="2px" 
      opacity="0.9"
      spinner-variant="bruvis"
      class="d-inline-block w-100"
      no-center
      style="inset:0px;"
      id = "globaloverlay"
    >
    <b-container class="main__container" :aria-hidden="globalbusy">
      <b-row>
<!---------------------------------------------------------------------------------------------->
        <!--b-col lg="12">
          <b-row-->
            <!--b-col v-if="salepoints.length > 1">
                <b-form-select
                  
                  v-model="salepoint_menu_rn"
                  :options="salepoints"
                  class="mb-2"
                  value-field="rn"
                  text-field="posname"
                  @change = "changeSalePoint(salepoint_menu_rn)"
                ></b-form-select>
            </b-col-->
            <!--b-col>
                <b-form-select
                  v-if="mealtimes.length !== 0"
                  v-model="mealtime"
                  class="mb-2"
                  @change = "changeMealtime(mealtime)"
                >
                  <template v-for="(mt,index) of  Object.keys(mealtimes)">
                    <b-form-select-option 
                      :key="index" 
                      :value="(mealtimes[mt]).rn"
                      :disabled="((mealtimes[mt]).timeTo <= (new Date()).getHours() + (new Date()).getMinutes()/100) && ((new Date(ondate)).getFullYear()+(new Date(ondate)).getMonth()+(new Date(ondate)).getDate() === (new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate())"
                    >
                      {{(mealtimes[mt]).mnemo}}
                    </b-form-select-option>
                  </template>  
                
                </b-form-select>
            </b-col-->

          <!-- (new Date()).getHours()-5 + (new Date()).getMinutes()/100  ) -->
          <!--  && ((new Date(ondate)).getFullYear()+(new Date(ondate)).getMonth()+(new Date(ondate)).getDate()) == ((new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate()) -->
          <!--:options="mealtimes.filter(item => item.timeTo >= (new Date()).getHours() + (new Date()).getMinutes()/100))"-->
            <!--b-col>
              <b-form-datepicker
                v-if="salepoints.length !== 0"
                @input = "changeOndate(ondate)" 
                v-model="ondate" 
                :date-disabled-fn="dateDisabled"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }" 
                locale="uk" 
                placeholder="Оберіть дату"
                start-weekday="1"
                :min= "minOndate"
                :max= "maxOndate"
                class="shadow-none mb-2"
                hide-header
                label-prev-decade="label1"
                label-next-decade="label2"
                ></b-form-datepicker>
            </b-col-->
          <!--/b-row>  

          
        </b-col-->
<!---------------------------------------------------------------------------------------------->        
<!--             @input.prevent = "changeOndate(ondates[ondateTabIndex])"  -->

        <b-col lg="12">
<!--
            <b-tabs 
              ref="ondates-tabs" 
              card 
              nav-class="scrollable-tabs-nav"
              v-model="ondateTabIndex"
            >
              <template v-slot:tabs-start>
                <b-icon icon="arrow-left-square-fill" variant="dark" class="h3"></b-icon>
              </template>
              <b-tab 
                v-for="(dt,tbinxd) in ondates" 
                :title="new Date(dt) | dateFormat('dddd D MMMM')" 
                title-item-class="text-nowrap"
                :key = "dt"
                @click.prevent = "changeOndate(dt)"
                :active="ondateTabIndexBuffer === tbinxd"
                content-class="mt-3"
              >
            </b-tab>
              <template v-slot:tabs-end>
                <b-icon icon="arrow-right-square-fill" variant="dark" class="h3"></b-icon>
              </template>
          </b-tabs>
-->
        <div class="w-100 mb-4 d-none d-sm-block">
          <b-tabs 
            ref="ondates-tabs"
            id="ondates-tabs" 
            pills 
            nav-class="scrollable-tabs-nav"
            active-nav-item-class = "btn btn-bruvis"
          >
            <template #tabs-start>
              <button 
                ref="scrollLeft" 
                class="vps-tabs-nav-btn-left btn btn-victor text-bruvis border-bruvis" 
                @click="clickLeft"
              >
                <b-icon icon="caret-left-fill"></b-icon>
              </button>
            </template>
            <!--b-tab 
              v-for="(dt,tbinxd) in ondates" 
              :title="new Date(dt) | dateFormat('dddd D MMMM')" 
              :ref="new Date(dt) | dateFormat('dddd D MMMM')"
              :class="(tbinxd === 0 )?'first':((tbinxd === ondates.length-1)?'last':'')"
              title-item-class="text-nowrap"
              :key = "dt"
              @click.prevent = "changeOndate(dt)"
              :active="ondateTabIndexBuffer === tbinxd"
              content-class="mt-3"
            >
            </b-tab-->
<!--                :class="(tbinxd === 0 )?'first':((tbinxd === ondates.length-1)?'last':'AAA')"-->            
            <template v-for="(dt,tbinxd) in ondates" >
              <b-tab
                :active="ondateTabIndexBuffer === tbinxd"
                :key = "dt"
                @click.prevent = "changeOndate(dt)"
                :title-item-class="(tbinxd === 0 )?'text-nowrap first shadow-none':((tbinxd === ondates.length-1)?'text-nowrap last shadow-none':'text-nowrap shadow-none')"
              >
                <template #title>
                  {{new Date(dt) | dateFormat('dddd D MMMM')}}
                  <div v-if="zakazy_payed_actual[dt]" class="zakazy-day-quant">{{(zakazy_payed_actual[dt]).length}}</div>
                </template>
              </b-tab>
            </template>  
            <template #tabs-end>
              <button 
                ref="scrollRight" 
                class="vps-tabs-nav-btn-right btn btn-victor text-bruvis border-bruvis" 
                @click="clickRight"
              >
                <b-icon icon="caret-right-fill"></b-icon>
              </button>
            </template>
          </b-tabs>
        </div>

          <!--MAIN WINDOW BEGIN------------------------
          :nav-wrapper-class="(salepoints.length === 1 && mealtimes.length === 1 && Object.keys(categories).length === 1)?'col-lg-0 col-md-0 col-sm-12 col-xs-12 w-100':'col-lg-3 col-md-4 col-sm-12 col-xs-12 w-100'"
          nav-wrapper-class="col-lg-3 col-md-4 col-sm-12 col-xs-12 w-100"
          -->
          <b-row>  
            <div v-if="salepoint.length == 0" class="m-0 my-4 py-4 d-none d-sm-block" style="height:3000px"></div>
            <b-card no-body class="w-100 min-vh-70 p-2 border-0" v-if="posts.length !== 0">
              <h6 class="alert alert-danger m-0 mb-3" v-if="alertmessage">{{ alertmessage }}</h6>              
              <b-tabs 
                v-if="Object.keys(categories).length > 0" 
                v-model="catIndex" 
                pills
                vertical
                :nav-wrapper-class="(salepoints.length === 1 && Object.keys(mealtimes).length === 1 && Object.keys(categories).length === 1)?'col-lg-0 col-md-0 col-sm-12 col-xs-12 w-100':'col-lg-3 col-md-4 col-sm-12 col-xs-12 w-100'"
                active-nav-item-class = "btn btn-bruvis"
                content-class=""
                nav-class="tabs-nav-cetegories"
              >
                <template v-slot:tabs-start>
                  <b-form-select
                    v-if="salepoints.length > 1"
                    v-model="salepoint_menu_rn"
                    :options="salepoints"
                    class="mb-2 shadow-none"
                    value-field="rn"
                    text-field="posname"
                    @change = "changeSalePoint(salepoint_menu_rn)"
                  />

                  <div class="date-block d-sm-none">
                    <div
                      v-if="salepoints.length !== 0" 
                      role="button" 
                      class="date-prev-btn btn btn-victor border-bruvis" 
                      @click="setPrevDay()"  
                      title="Попередній день"
                      :disabled="ondateTabIndex === 0"
                    >
                      <b-icon :icon="(ondateTabIndex === 0)?'stop':'caret-left-fill'"></b-icon>
                    </div>
                    <div class="datepicker">  
                      <b-form-datepicker
                        v-if="salepoints.length !== 0"
                        @input = "changeOndate(ondate)" 
                        v-model="ondate" 
                        :date-disabled-fn="dateDisabled"
                        :date-format-options="{ month: 'long', day: 'numeric', weekday: 'long' , year: 'numeric'}" 
                        locale="uk" 
                        placeholder="Оберіть дату"
                        start-weekday="1"
                        :min= "minOndate"
                        :max= "maxOndate"
                        class="shadow-none border-bruvis mb-2 d-sm-none"
                        style="font-size:0.8em;height:3em;"
                        hide-header
                        label-prev-decade="label1"
                        label-next-decade="label2"
                      />
                    </div>
                    <div 
                      v-if="salepoints.length !== 0"
                      role="button" 
                      class="date-next-btn btn btn-victor border-bruvis" 
                      @click="setNextDay()" 
                      title="Наступний день"
                      :disabled="ondateTabIndex === ondates.length-1"
                    >
                      <b-icon :icon="(ondateTabIndex === ondates.length-1)?'stop':'caret-right-fill'"></b-icon>
                    </div>
                  </div>
<!--v-if="mealtimes.length !== 0"-->
                    <b-form-select
                      v-if="Object.keys(mealtimes).length > 1"
                      v-model="mealtime"
                      class="mb-2 shadow-none"
                      @change = "changeMealtime(mealtime)"
                    >
                      <template v-for="(mt,index) of  Object.keys(mealtimes)">
                        <b-form-select-option 
                          :key="index" 
                          :value="(mealtimes[mt]).rn"
                          :disabled="((mealtimes[mt]).timeTo <= (new Date()).getHours() + (new Date()).getMinutes()/100) && ((new Date(ondate)).getFullYear()+(new Date(ondate)).getMonth()+(new Date(ondate)).getDate() === (new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate())"
                        >
                          {{(mealtimes[mt]).mnemo}}
                        </b-form-select-option>
                      </template>  
                    </b-form-select>
<!-------------------------------------------->
                     <b-form-select
                        v-if="Object.keys(categories).length > 0"
                        v-model="catIndex"
                        class="d-block d-md-none"
                      >
                        <template v-for="(ct,ctind) of  Object.keys(categories)">
                          <b-form-select-option 
                            :key="ctind" 
                            :value="ctind"
                          >
                            {{(categories[ct]).mnemo}}
                          </b-form-select-option>
                        </template>

                     </b-form-select>

<!-------------------------------------------->
                </template>
                <b-tab 
                  v-for="(cat, index) of categories" 
                  :key="index" 
                  :title="cat.mnemo"
                  :title-link-class="(Object.keys(categories).length > 1)?'d-none d-md-block shadow-none':'d-none'"
                >
                    <!--div class="text-right">
                      <b-button @click="dishes_sort('mnemo_asc')" :class="(sort_mode == 'mnemo_asc')?'m-2 bg-victor text-dark shadow-none':'m-2 bg-white text-dark shadow-none'" title="Сортувати за назвою">
                        <b-icon icon="sort-alpha-down" />                      
                      </b-button>
                      <b-button @click="dishes_sort('price_asc')" :class="(sort_mode == 'price_asc')?'m-2 bg-victor text-dark shadow-none':'m-2 bg-white text-dark shadow-none'" title="Сортувати за зростанням вартості">  
                        <b-icon icon="sort-down-alt" />
                      </b-button>  
                      <b-button @click="dishes_sort('price_desc')" :class="(sort_mode == 'price_desc')?'my-2 ml-2 bg-victor text-dark shadow-none':'my-2 ml-2 bg-white text-dark shadow-none'" title="Сортувати за зменьшенням вартості">  
                        <b-icon icon="sort-down" />
                      </b-button>    
                    </div-->
                    <b-row>
                    <template v-for="(dish,index2) of dishes">  
                      <b-col
                       xs="12" sm="6"
                        :xl = "(salepoints.length === 1 && mealtimes.length === 1 && Object.keys(categories).length === 1)?'2':'3'"
                        :lg =  "(salepoints.length === 1 && mealtimes.length === 1 && Object.keys(categories).length === 1)?'2':'3'"
                        :md =  "(salepoints.length === 1 && mealtimes.length === 1 && Object.keys(categories).length === 1)?'3':'4'"
                        class="mb-2" v-if="cat.childs.indexOf(dish.rp)!==-1" :key="index2"
                       >
                        <div :class="(dish.quant_cart > 0)?'my-1 mx-0 p-0 border border-success rounded card-dish':'my-1 mx-0 p-0 border rounded card-dish'">
                          <b-aspect aspect="3:2" class="dish-banner rounded-top">
                            <b-img @click="showDish(dish.rn)" :src="(dish.photoExists)?dish.photoURL:'/img/empty.png'" class="w-100" style="position:absolute;left:0"></b-img>
                          </b-aspect>
                          <b-aspect aspect="16:9" class="card-dish-legend">
                            <div class="dish-mnemo">{{dish.mnemo}}</div>
                            <div class="price-outcome" v-if="dish.outcome">{{dish.outcome}}</div>
                            <div class="price">
                              <span class="price-uah">{{dish.priceInt}}</span><span class="price-kop">{{dish.priceKop}}</span><span class="price-hrn">грн</span>
                            </div>
                            <div @click="addCart(dish.rn)" class="add_cart_item" role="button">
                              <b-icon icon="cart-plus" variant="dark" class="h3"></b-icon>
                              <div class="item_quant" v-show="dish.quant_cart > 0">{{ dish.quant_cart }}</div>  
                            </div>
                            <b-iconstack @click="setCartQuant(dish.rn, 0)" role="button" v-show="dish.quant_cart > 0" class="h4 ico-remove-item">
                              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                              <b-icon stacked icon="x-circle" variant="dark"></b-icon>
                            </b-iconstack>
                            <div v-if="dish.specmarkRNs.length>0" class="dish_smarks">
                              <template v-for="(smrk,index3) of dish.specmarkRNs">
                                <b-badge  :key="index3" v-if="specmarks[smrk]!=null" :class="'mark_'+(specmarks[smrk]).name">
                                  {{(specmarks[smrk]).name}}
                                </b-badge>
                              </template>  
                            </div> 
                            <!--template v-if="dish.specmarkRNs[0]">
                              <b-img v-for="(mark,index3) in dish.specmarkRNs" :key="index3" :src="(specmarks[mark]).photoURL" :class="'mark_'+(specmarks[mark]).name"></b-img>
                            </template-->
                          </b-aspect>
                        </div>
                      </b-col>  
                    </template>
                    </b-row>  
                </b-tab>
                <template v-slot:tabs-end v-if="html_left_block">
                  <div class="d-none d-md-block my-2 w-100" v-html="html_left_block"></div>
                </template>  
              </b-tabs>
              <div v-else class="p-2 h5">
                На цей час меню не сформовано
              </div>
            </b-card>
          </b-row>
          <!--MAIN WINDOW END-------------------------->          
        </b-col>
<!---------------------------------------------------------------------------------------------->        
      </b-row>
    </b-container>
    </b-overlay>

    <small class="text-white">{{cart_sum}}-{{cart_count}}</small>
    <!--/b-overlay-->

<!--  MODALS  -->

<!--LOGIN-------------------------------------------------------------------------->    

          <b-modal 
            id="login-modal2" ref="login-modal2"
            hide-header-close 
            hide-footer
            hide-header 
            centered 
            v-model="needlogin" 
            @hidden="busy = false" 
            class="p-0"
            :no-close-on-backdrop="true"
            >
              <div style="" class="login-modal-body" >
                <div v-bind:class="{ error: emptyFields }">
                  <div class="form-group login-form">
                    <b-overlay
                      :show="busy"
                      rounded 
                      variant="white"
                      blur="2px" 
                      opacity="0.6"
                      spinner-variant="bruvis"
                      class="d-inline-block w-100"
                    >
                      <input v-model="nameLogin" type="text" class="form-control my-2" placeholder="Логін" required>
                      <input v-model="passwordLogin" type="password" class="form-control my-2" placeholder="Пароль" required>
                      <div v-if="error_login_msg" class="text-danger my-2">{{error_login_msg}}</div>
                      <b-button class="mt-3 shadow-none" block @click="doLogin" :disabled="busy">Увійти</b-button>
                    </b-overlay>  
                  </div>
                </div>
                <div class="small text-center powered">
                  Powered by <a target="_blank" href="https://jsolutions.ua/ua/avtomatizatciya-korporativnogo-pitaniya"><b-img src="/img/logo_jsol.png" width="71"></b-img></a>
                </div> 
              </div>
          </b-modal>

<!-- Dish target ----------------------------------------------------------              size="sm" --->
            <b-modal id="dish-target-modal" 
              hide-header
              hide-header-close 
              centered 
              scrollable 
              ok-only
              size = "xl"
              @hidden = "target_dish = null"
              @shown  = "target_dish_quant = (dishes[target_dish]).quant_cart"              
            >

              <b-card
                v-if="target_dish !== null && dishes[target_dish] !== null"
                class="mb-2 border-white"
              >
                <b-row no-gutters>
                  <b-col md="6">
                    <b-card-img :src='(dishes[target_dish]).photoURL.replace("thumb.jpg","middle.jpg")' alt="Image" class="rounded-0"></b-card-img>
                  </b-col>
                  <b-col md="6">
                    <b-card-body 
                      :title="(dishes[target_dish]).mnemo"
                      :sub-title="(dishes[target_dish]).info"
                    >
                      <b-card-text>
                        
                        <div>
                          {{(dishes[target_dish]).price | toCurrency}}
                          <template v-if="(dishes[target_dish]).outcome != null">
                            &nbsp;/&nbsp;{{(dishes[target_dish]).outcome}}
                          </template>  
                        </div>  

                        <div class="display:block;"><!--margin:0 auto;-->
                        <GChart
                          type="PieChart"
                          :settings="{ packages: ['corechart'] }"
                          :data="(dishes[target_dish]).energyParamList"
                          v-if="(dishes[target_dish]).energyParamList"
                          :options='{ 
                                    "is3D":"true", 
                                    "title":((dishes[target_dish]).energy)?"Енергетична цінність "+(dishes[target_dish]).energy+" кКал":"БЖУ",
                                    "width":"400",
                                    "height":"300"
                                    }'
                                    class='gchart'
                        />
                        </div>
                        
                        <div v-if="(dishes[target_dish]).specmarkRNs.length>0" class="target-smrk">
                          <b-badge v-for="(smrk,index) of (dishes[target_dish]).specmarkRNs" :key="index">
                            <template v-if="specmarks[smrk]!=null">{{(specmarks[smrk]).name}}</template>
                          </b-badge>
                        </div> 

                        <div v-if="(dishes[target_dish]).debug">
                          <pre style="font-size:11px;">
                            {{(dishes[target_dish]).debug}}
                          </pre>  
                        </div>
                      
                      </b-card-text>
                    </b-card-body>  
                  </b-col>
                </b-row>    
              </b-card>

              <template #modal-footer="{ ok, cancel }">
                <b-form-spinbutton 
                  v-model="target_dish_quant" 
                  min="0" max="100"
                  @change="target_dish_quant_changed = true" 
                  inline></b-form-spinbutton>
                <b-button 
                  variant="success" 
                  @click="setCartQuant((dishes[target_dish]).rn, target_dish_quant),ok()"
                  :disabled = "target_dish_quant == (dishes[target_dish]).quant_cart"
                >
                 <b-icon icon="cart-plus" variant="light" class="h4 m-0"></b-icon>
                </b-button>
                <b-button variant="bruvis" @click="cancel()">
                  Закрити
                </b-button>
              </template>      
            </b-modal>
<!--Dish target END----->

<!--BASKET BEGIN--------------------------------------------------------------------->
          <b-modal id="basket-modal2" hide-header-close- centered scrollable size="lg" :no-close-on-backdrop="true">
            <template #modal-title>
              <b-icon icon="cart4" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Замовлення на {{(mealtimes[mealtime]).mnemo}} ({{new Date(ondate)  | dateFormat('dddd D MMMM')}})</span>
              <!--b-icon icon="x-square" class="rounded p-0" font-scale="1.5" role="button" @click="close()"></b-icon-->
            </template>
            <table class="table table-striped">
              <tbody>
                <tr v-for="ci of cart" :key="ci.menu_rn">
                  <td>{{cartItems[ci.menu_rn]['mnemo']}}</td>
                  <td class="align-middle"><b-icon icon="trash" @click="setCartQuant(ci.menu_rn, 0)" title="Remove" variant="" class="h4 m-1" role="button"></b-icon></td>
                  <td><b-form-spinbutton 
                    :id="cart.menu_rn+'_quant'" 
                    v-model="ci.quant" 
                    @change="setCartQuant(ci.menu_rn, $event)"
                    min="1" max="100" size="sm" 
                    inline></b-form-spinbutton>
                  </td>
                  <!--td class="p-3 text-right" :title="ci.quant+' х '+cartItems[ci.menu_rn]['price']">{{ parseFloat(ci.quant) * parseFloat(cartItems[ci.menu_rn]['price'])}}&nbsp;<span>&#8372;</span></td-->
                  <td class="p-3 text-right" :title="ci.quant+' х '+cartItems[ci.menu_rn]['price']">{{ ci.quant * Math.round(cartItems[ci.menu_rn]['price'] * 100) / 100}}&nbsp;<span>&#8372;</span></td>
                </tr>
                <tr>
                  <td></td>  
                  <td></td>  
                  <th colspan="2" class="text-right text-secondary">Разом: <span class="text-body">{{cart_sum}}</span>&nbsp;&#8372;</th>  
                </tr>
              </tbody>  
            </table>
            <template #modal-footer> <!--="{ reservOrder, payOrder()}-->
              <!--span class="mr-auto text-secondary font-weight-bold h5">До сплати: <span class="text-body">{{cart_sum}}</span>&nbsp;&#8372;</span-->
              <b-button variant="success" @click="reservOrder()" :disabled="cart.length === 0">
                <b-icon icon="bag-check"></b-icon> Замовити
              </b-button>
              <!--b-button variant="danger" @click="payOrder()">
                <b-icon icon="credit-card"></b-icon> Оплатити {{cart_sum}}&nbsp;&#8372;
              </b-button-->
              <!--b-button size="sm" variant="outline-secondary" @click="close()">
                Відміна
              </b-button-->
          </template>
          </b-modal>

<!--BASKET END--------------------------------------------------------------------->

<!--ORDERS BEGIN--------------------------------------------------------------------->
          <b-modal 
            id="orders-modal" 
            hide-header-close- 
            centered 
            scrollable 
            size="md" 
            :no-close-on-backdrop="true"
            @shown = "widget_is_visible = false,select_pay_method_visible=false,selectAllOff()"
          >
            <!-- //eslint-disable-next-line -->
            <template #modal-title v-if="!widget_is_visible && !select_pay_method_visible">
              <b-icon icon="cart4" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Неоплачені замовлення</span>
              <!--b-icon icon="x-square" class="rounded p-0" font-scale="1.5" role="button" @click="close()"></b-icon-->
            </template>
            <template #modal-title v-else>
              <b-icon icon="credit-card" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Оплата замовлень</span>
              <!--b-icon icon="x-square" class="rounded p-0" font-scale="1.5" role="button" @click="close()"></b-icon-->
            </template>

            <b-overlay
              :show="ordersbusy"
              rounded
              variant="white"
              blur="2px" 
              opacity="0.9"
              spinner-variant="bruvis"
              class="d-inline-block w-100"
              style="inset:0px;"
            >
            <b-form-checkbox-group
                  id="checkbox-group-2pay"
                  v-model="selectedTopay"
                  name="selected2pay"
                  @change = "recalcSumToPay"
                  switches
                  class="bruvis"
            >
            <!--code>{{selectedTopay}}</code>
            <code>{{allSelected}}</code-->
            
            <table 
              class="table orders-table" 
              sticky-header="true" 
              v-if="Object.keys(zakazy).length > 0"
              v-show="!widget_is_visible && !select_pay_method_visible"
            >
              <thead>
                  <th class="align-bottom border-top-0 border-bottom-0" width="1">Дата</th>
                  <th class="align-bottom border-top-0 border-bottom-0" width="500">Час</th>
                  <th class="align-bottom border-top-0 border-bottom-0 pl-3" colspan="2">
                    <!--b-form-checkbox
                            :value="1"
                            switches
                    -->
                      <b-icon 
                        @click="selectAll()" 
                        :icon="(allSelected)?'toggle-on':'toggle-off'" 
                        class="mr-2"
                        role="button"
                        font-scale="1.4"
                      ></b-icon>Сума
                    <!--/b-form-checkbox-->
                  </th>
              </thead>
              <tbody>
                <template v-for="(zk,zkday) in zakazy">
                  <tr :key="zkday" class="bg-bruvis text-white">
                    <td colspan="4" class="pt-1 pb-1">{{ new Date(zkday) | dateFormat('dddd D MMMM')}}</td>
                  </tr>  
                  <template v-for="z of zk">
                    <!--tr :key="z.rn" :class="( mealtimes[z.mealtime].timeTo < ((new Date()).getHours() + (new Date()).getMinutes()/100) && ((new Date(zkday)).getFullYear()+(new Date(zkday)).getMonth()+(new Date(zkday)).getDate()) == ((new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate()) )?'text-muted order-failed orders-specs-row':'orders-specs-row'"-->
                    <tr :key="z.rn" :class="( new Date(z.deadln) < new Date() )?'text-muted order-failed orders-specs-row':'orders-specs-row'">
                      <td 
                        v-b-toggle="'more'+z.rn"
                        :title="z['rn']"
                        class="td-active"
                      >
                        <b-icon icon="caret-down-fill" class="when-closed" font-scale="1.0"></b-icon>
                        <b-icon icon="caret-up-fill" class="when-open" font-scale="1.0"></b-icon>
                      </td>
                      <td class="align-middle td-active" 
                        role="button"
                        :title="(mealtimes[z.mealtime]).timeFr + '-' + (mealtimes[z.mealtime]).timeTo"
                        v-b-toggle="'more'+z.rn"
                      >{{(mealtimes[z.mealtime]).mnemo}}</td> 
                      <td class="align-middle">
                        <!--v-if = "( (mealtimes[z.mealtime].timeTo > (new Date()).getHours() + (new Date()).getMinutes()/100 )) || ((new Date(zkday)).getFullYear()+(new Date(zkday)).getMonth()+(new Date(zkday)).getDate()) != ((new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate())"-->
                        <b-form-checkbox
                            v-if="new Date(z.deadln) > new Date()"
                            :value="z.rn"
                            @change="selectAllOff()"
                            role="button"
                        >{{z.sumToPay | toCurrency}}</b-form-checkbox>
                      </td>
                      <td width="1">
                        <b-icon 
                          icon="trash" 
                          v-if="new Date(z.deadln) > new Date()"
                          @click="removeOrder(z.rn)" 
                          title="Видалити" 
                          class="h4 m-1" 
                          role="button"
                        />
                      </td>                   
                    </tr>
                    <tr :key="z.rn" class="p-0">
                      <td colspan="4" class="p-0">
                        <b-collapse :id="'more'+z.rn" class="m-4 orders-menu-spec">
                          <template v-for="(spec,index2) of z.specs">
                            <li :key="index2">{{spec.menu}}
                              <template v-if="spec.quant > 1"> (x{{spec.quant}})</template>
                            </li>
                          </template>
                          <div class="border-top mt-3 pt-2" v-if="new Date(z.deadln) > new Date()">Граничний термін оплати : {{new Date(z.deadln) | dateFormat('D MMMM H:mm', dateFormatConfig)}}</div>
                          <div class="alert alert-danger mt-2" v-else>Термін сплати замовлення сплив ({{new Date(z.deadln) | dateFormat('D.MM.YYYY H:mm', dateFormatConfig)}})</div>
                        </b-collapse>                        
                      </td>  
                    </tr>
                  </template>  
                </template>  
              </tbody>  
            </table>
            <div v-else class="text-center h4">
              Замовлень немає
            </div>  
            </b-form-checkbox-group>
            </b-overlay>

            <div v-if="select_pay_method_visible && !widget_is_visible" class="method-of-pay mb-4">
              <b-card-group deck>
                  <b-card role="button" @click="PayCreditCard(selectedTopay.join())">
                    <b-img src="/img/liqpay_0.png" fluid/>
                  </b-card>
                  <b-card role="button" @click="PayClubCard(selectedTopay.join())">
                    <b-row align-v="end">
                      <b-col>
                        <b-img src="/img/ballance2.png" fluid/>
                      </b-col>
                      <b-col align="center">
                        <h5>Клубна карта</h5>
                      </b-col>
                    </b-row>
                  </b-card>
              </b-card-group>
            </div>

            <div id="liqpay_checkout"></div>
            <template #modal-footer>
              
              <!--b-button variant="success" @click="getPayStatus()" :disabled="selectedTopay.length == 0">
                Get Pay Status
              </b-button-->
              <b-button 
                variant="success" 
                @click="payOrders()" 
                :disabled="selectedTopay.length == 0"
                v-if="Object.keys(zakazy).length > 0 && salepoint.legalRN !=null"
                v-show="!widget_is_visible && !select_pay_method_visible"
              >
                <b-icon icon="credit-card"></b-icon> Оплатити {{amount}}&nbsp;&#8372;
              </b-button>
              <b-button 
                v-if="select_pay_method_visible && !widget_is_visible"
                variant="bruvis" 
                @click="select_pay_method_visible=false"
              >
                &lt;&lt;&nbsp;Повернутись
              </b-button>
              <b-button variant="bruvis" @click="$bvModal.hide('orders-modal')">
                Закрити
              </b-button>
          </template>
          </b-modal>

<!--ORDERS END--------------------------------------------------------------------->

<!-- Zakazy Payed Begin ------------------------------------------------------------>
            <b-modal id="zakazy-payed-modal" hide-header-close- 
              centered scrollable size="lg" :no-close-on-backdrop="true" ok-only
              ok-variant="bruvis"
              ok-title="Закрити"
            >
              <template #modal-title>
                <b-icon icon="cart4" class="rounded bg-success p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Оплачені замовлення </span>
              </template>
              <ZakazyPayed 
                :zakazy_payed="zakazy_payed" 
                :mealtimes="mealtimes"
                @ondelete = "init_zakazy_afterpay()"
                @needlogin = "needlogin = true"
              ></ZakazyPayed>


              <!-- template v-if="zakazy_payed != null && Object.keys(zakazy_payed).length > 0">
                <b-card no-body>
                  <b-tabs small pills card vertical>
                    <b-tab v-for="(dt,index) of  Object.keys(zakazy_payed)" :title="new Date(dt) | dateFormat('dddd D MMMM', dateFormatConfig)" :key="index" class="text-center">
                      <template v-if="zakazy_payed[dt] != null">
                        <div class="perforated_check mx-auto" v-for="(ch,index2) of zakazy_payed[dt]" :key="index2">
                          <svg width="200px" viewBox="0 0 300 5" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0,0 300,0" fill="none" stroke="#e2e3de" stroke-dasharray="3 3" stroke-width="20"/>
                          </svg>
                          <table class="teble mx-auto w-100">
                            <tr :key="index2">
                              <th colspan=2 class="text-center pb-3 pt-3">{{(mealtimes[ch.mealtime]).mnemo}} : рахунок № {{ch.rn}}</th>
                            </tr>
                            <tr v-for="(sp,index3) in ch.specs" :key="index3">
                              <td class="text-left pr-3 pl-1">{{sp.menu}}<template v-if="sp.quant>1"> (x{{sp.quant}})</template></td>
                              <td class="text-right text-nowrap pl-3 pr-1"><template v-if="sp.quant>1">{{sp.quant}} x </template>{{formatPrice(sp.price)}}</td>
                            </tr>
                            <tr class="bordered-top" :key="index2">
                              <td class="text-left pr-3 pl-1">Знижка</td>
                              <td class="text-right pl-3 pr-1">- {{formatPrice(ch.totalDiscountSum)}}</td>
                            </tr>
                            <tr :key="index2">
                              <th class="text-left pr-3 pl-1">Разом сплачено</th>
                              <th class="text-right pl-3 pr-1">{{formatPrice(ch.sumToPay)}}</th>
                            </tr> 
                          </table>  
                        </div>
                      </template>
                    </b-tab>
                  </b-tabs>
                </b-card>  
              </template>
              <template v-else>
                <div class="text-center">
                  <h5>Замовлень немає</h5>
                </div>
              </template-->    
            </b-modal>
<!-- Zakazy Payed END ------------------------------------------------------------> 

<!--USER Profile--------------------------------------------------------------------->    
<b-modal 
  id="userprofile-modal" 
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
>
  <template #modal-title>
    <b-icon icon="file-person" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Профіль користувача</span>
  </template>
  <UserProfile 
    :user="user" 
    :clubcards="clubcards"
    @onRefill = "init_afterrefill()"
    @needlogin = "needlogin = true"
  />
  
</b-modal>

<!--Archive--------------------------------------------------------------------->    
<b-modal 
  id="archive-modal" 
  size="lg"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
>
  <template #modal-title>
    <b-icon icon="box-seam" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Архів замовлень</span>
  </template>
  
  <ArchivZakazov
    :mealtimes="mealtimes" 
    @needlogin = "needlogin = true"
  />
  
</b-modal>              


<!--PAY begi--------------------------------------------------------------------->    
<!--          <b-modal :show.sync="modalPayVisibile">
              <div slot="modal-header" class="modal-header">
                <h4 class="modal-title">{{ modalPayTitle }}Title</h4>
              </div>
              <div slot="modal-body" class="modal-body">
                Content
                  {{ modalPayContent }}
              </div>
          </b-modal>
-->          
<!--PAY end---------------------------------------------------------------------> 

<!--debug begi--------------------------------------------------------------------->

          <b-modal id="debug" hide-header-close- centered scrollable size="lg">
            <b-card no-body class="w-100">
              <h4>Salepoint</h4>
              <pre>
                {{salepoint}}
              </pre>  
              <h4>Ordfood</h4>
              <pre>
                {{ordfood}}
              </pre>
              <h4>Checkbase</h4>
              <pre>              
                {{checkbase}}
              </pre>
              <h4>Zakazy</h4>
              <pre>              
                {{zakazy}}
              </pre>
              <h4>Zakazy payed</h4>
              <pre>              
                {{zakazy_payed_actual}}
              </pre>
              <h4>DebugInfo</h4>
              <pre>              
                {{debug}}
              </pre>         
              <h4>Dishes</h4>
              <pre>
                {{dishes}}
              </pre>
              <pre>
              
              </pre>
            </b-card> 
          </b-modal>
<!--debug end--------------------------------------------------------------------->

  </div>
</template>



<script>
import ZakazyPayed from './components/zakazy_payed.vue'
import UserProfile from './components/userprofile.vue'
import ArchivZakazov from './components/archive.vue'
import { GChart } from 'vue-google-charts/legacy'

export default {
  name: 'App',
  components: {
    ZakazyPayed,  
    UserProfile,
    ArchivZakazov,
    GChart
  },
  data: () => ({
    token: null,
    posts: [],
    errors: [],
    categories: [],
    categories_not_empty: [],
    catIndex: 0,
    //categoriesfilter: [],
    dishes: [],
    sort_mode: null,
    target_dish: null,
    target_dish_quant: null,
    specmarks: [],
    salepoints: null,
    salepoint: [],
    salepoint_menu_rn: null,
    mealtimes: [],
    mealtime: null,
    cart: [],
    cartItems: [],
    cart_sum: 0,
    cart_count: 0,
    value2: null,

    nameLogin: "", //supervisor
    passwordLogin: "", //admin
    emptyFields: false,

    username: null,
    needlogin: false,
    needlogin2: false,

    error_login_msg: '',
    alertmessage: null,
    noopInterval: null,
    mark_tmp: null,

    ondates: null,
    ondate: null,
    holidays: null,

   // loading: true
    busy: false,
    globalbusy: false,
    ordersbusy: false,

    ordfood : [],
    checkbase : [],

    orders: [],
    checks: [],
    zakazy: {},
    zakazy_payed: {},
    amount: 0,
    
    clubcards: [],
    user: null,
    refill_modal_visible: false,

    //isMouseDown: false,
    //startX: null,
    scrollLeft: null,
    navElement: null,
    ondateTabIndex: null,
    ondateTabIndexBuffer: null,


    //modalPayVisibile: false,
    //modalPayTitle: null,
    //modalPayContent: null,
    widget_is_visible: false,
    select_pay_method_visible: false,

    selectedTopay: [],
    allSelected: false,

    chartData: [
      ["Task", "Hours per Day"],
      ["Work", 11],
      ["Eat", 2],
      ["Commute", 2],
      ["Watch TV", 2],
      ["Sleep", 7]
    ],
    chartOptions: {
      chart: {
        title: "БЖУ",
        is3D: true
      }
    },
    html_left_block: null,
    logo: null,
    debug: ''
  }),
  computed: {
    minOndate: function(){
      //const now = new Date()
      //const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      //const minDate = new Date(today)
      const minDate = new Date()
      //return minDate
      return (this.ondates != null)?new Date(this.ondates[0]):minDate
    },
    maxOndate: function(){
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)
      return (this.ondates != null)?new Date(this.ondates[this.ondates.length-1]):maxDate
    },
    zakazy_payed_actual: function(){
      var zk,ret = {}
      Object.keys(this.zakazy_payed).forEach((d) => {
        zk = []
        this.zakazy_payed[d].forEach((z) => {
          if(z['canceled'] !== true) zk.push(z)
        })
        if(zk.length > 0) ret[d] = zk
      })
      return ret
    }
    

  },
  created() {
    let liqpay = document.createElement('script');    
    liqpay.setAttribute('src',"https://static.liqpay.ua/libjs/checkout.js");
    document.head.appendChild(liqpay);
  },
  mounted() {
    this.init()
      
    this.navElement = this.$refs["ondates-tabs"].$el.querySelector("ul")
/*
    this.navElement.addEventListener("mousedown", this.onMouseDown);
    this.navElement.addEventListener("mouseup", this.onMouseUp);
    this.navElement.addEventListener("mousemove", this.onMouseMove);
    this.navElement.addEventListener("mouseleave", this.onMouseLeave);

    this.navElement.addEventListener("touchstart", this.onTouchStart);
    this.navElement.addEventListener("touchmove", this.onTouchMove);
*/    

  },
/*  onBeforeDestroy() {
    this.navElement.removeEventListener("mousedown", this.onMouseDown);
    this.navElement.removeEventListener("mouseup", this.onMouseUp);
    this.navElement.removeEventListener("mousemove", this.onMouseMove);
    this.navElement.removeEventListener("mouseleave", this.onMouseLeave);

    this.navElement.removeEventListener("touchstart", this.onMouseDown);
    this.navElement.removeEventListener("touchmove", this.onMouseMove);
  },
  */
  methods: {
    init(rn,ondate,mealtime){
      this.globalbusy = true
      
      if(rn == null) rn=(this.salepoint.rn != null)?this.salepoint.rn:null;
      if(ondate == null) ondate=(this.ondate != null)?this.ondate:null;
      if(mealtime == null) mealtime=(this.mealtime != null)?this.mealtime:null;
      
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      var postData = {"action":"init","rn":rn,"ondate":ondate,"mealtime":mealtime}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" ){
            this.posts      = response.data
            this.dishes     = this.posts.dishes
            this.specmarks  = this.posts.specmarks
            this.salepoints = this.posts.salepoints
            this.salepoint  = this.posts.salepoint
            this.salepoint_menu_rn = this.salepoint.rn
            this.mealtimes = this.posts.mealtimes
            this.mealtime  = this.posts.mealtime
            this.categories = this.posts.categories  
            this.ondates = this.posts.ondates
            this.ondate  = this.posts.ondate
            this.ondateTabIndex = this.posts.ondate_index
            this.ondateTabIndexBuffer = this.posts.ondate_index
            this.holidays= this.posts.holibase
            this.cart       = this.posts.order.basket
            this.cartItems  = this.posts.order.item
            this.cart_count = parseInt(this.posts.order.summary.sum_count)
            this.cart_sum   = parseFloat(this.posts.order.summary.sum_price)
            this.username   = this.posts.auth.username
            this.token      = this.posts.auth.token
            this.needlogin  = false
            this.sort_mode  = this.posts.sort
              //if(this.sort_mode !== null) this.dishes_sort(this.sort_mode) 
            this.ordfood    = this.posts.ordfood  
            this.checkbase  = this.posts.checkbase

            this.orders    = this.posts.orders  
            this.checks    = this.posts.checks
            this.zakazy    = this.posts.zakazy
            this.zakazy_payed    = this.posts.zakazy_payed
            this.alertmessage    =  this.posts.message

            this.clubcards =  this.posts.clubbase
            this.user      =  this.posts.user

            if(this.posts.html_leftblock) this.html_left_block = this.posts.html_leftblock
            if(this.posts.debug) this.debug = this.posts.debug
            if(this.posts.logo) this.logo   = this.posts.logo

            this.globalbusy = false
            //this.$toasted.success('Loaded successfull').goAway(2000)
          }else{
            this.globalbusy = false
            if(response.data.error=="auth"){
              this.needlogin = true
            }else if(response.data.error=="tokenout"){
              this.needlogin = true
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });

        if(this.noopInterval === null)
          this.noopInterval = setInterval(function(){
                            this.$axios.post('/', {"action":"noop"} )
                                  .then((response) => {
                                    if(response.data.success == false || response.data.success == 'false'){
                                      if(response.data.error == "auth"){
                                        this.needlogin = true
                                        clearInterval(this.noopInterval)
                                      }else if(response.data.error == "tokenout"){
                                        this.needlogin = true
                                        clearInterval(this.noopInterval)
                                      }else if(response.data.message != ''){
                                        this.alertmessage = response.data.message
                                        if(response.data.wait != true){
                                          clearInterval(this.noopInterval)
                                        }
                                        //this.$toasted.error(response.data.message).goAway(5000)
                                      }
                                    }else{
                                      this.alertmessage = ''
                                    }
                                  })
                                  .catch(error => {
                                    console.error('There was an error!', error)
                                    clearInterval(this.noopInterval)
                                  });
                            }.bind(this), 60000);

    },
    init_zakazy(){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      var postData = {"action":"init_zakazy"}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" ){
            this.posts          = response.data
            this.zakazy         = this.posts.zakazy
            //this.globalbusy = false
          }else{
            //this.globalbusy = false
            if(response.data.error=="auth"){
              this.needlogin = true
            }else if(response.data.error=="tokenout"){
              this.needlogin = true
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },
    init_zakazy_afterpay(){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      var postData = {"action":"init_zakazy_afterpay"}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" ){
            this.posts          = response.data
            this.zakazy         = this.posts.zakazy
            this.zakazy_payed   = this.posts.zakazy_payed
            this.clubcards      = this.posts.clubbase
            //this.globalbusy = false
          }else{
            //this.globalbusy = false
            if(response.data.error=="auth"){
              this.needlogin = true
            }else if(response.data.error=="tokenout"){
              this.needlogin = true
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },
    init_afterrefill(){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      var postData = {"action":"afterrefill"}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" ){
            this.posts    = response.data
            this.clubcards= this.posts.clubbase
          }else{
            if(response.data.error=="auth" || response.data.error=="tokenout"){
              this.needlogin = true
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },
    init_order(rn,ondate,mealtime){

        if(rn == null) rn=(this.salepoint.rn != null)?this.salepoint.rn:null;
        if(ondate == null) ondate=(this.ondate != null)?this.ondate:null;
        if(mealtime == null) mealtime=(this.mealtime != null)?this.mealtime:null;

        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer_'+this.token
          },
          'withCredentials': true
        }
        var postData = {"action":"init_order","rn":rn,"ondate":ondate,"mealtime":mealtime}
        this.$axios.post('/', postData,  axiosConfig)
                        
                .then((response) => {
                    if(response.data.success != 'false'){
                      this.posts      = response.data

                      this.cart       = this.posts.order.basket
                      this.cartItems  = this.posts.order.item
                      this.cart_count = parseInt(this.posts.order.summary.sum_count)
                      this.cart_sum   = parseFloat(this.posts.order.summary.sum_price)
                      this.ordfood    = this.posts.ordfood  
                      this.checkbase  = this.posts.checkbase
                      this.orders    = this.posts.orders  
                      this.checks    = this.posts.checks
                      this.zakazy    = this.posts.zakazy
                      this.zakazy_payed    = this.posts.zakazy_payed

                      this.$toasted.success('Updated successfull').goAway(2000)

                    }else{
                      if(response.data.error=="auth"){
                        this.needlogin = true
                      }else if(response.data.error=="tokenout"){
                        this.needlogin = true
                      }
                    }
                   
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
        //this.loading = false
    },
    dishes_sort(mode){
      switch (mode) {
          case 'mnemo_asc':
            this.dishes = this._.orderBy(this.dishes, 'mnemo', 'asc').map(item =>  {
                //const ob = {}
                //ob[item.rn] = item
                return {'item.rn' : item}
            
            })
            break  
          case 'price_asc':
            this.dishes = this._.orderBy(this.dishes, 'price', 'asc')
            break
          case 'price_desc':  
            this.dishes = this._.orderBy(this.dishes, 'price', 'desc')
            break
          default:
            return true
      }
      if(this.sort_mode !== mode){
        this.$axios.post('/', {"action":"setsort","rn": mode} )
          .then((response) => {
            if(response.data.success == false || response.data.success == 'false'){
              if(response.data.error == "auth" || response.data.error == "tokenout"){
                this.needlogin = true
              }
            }
          })
        this.sort_mode = mode  
      }    

/**
 * 
 * 
 * _(data)
    .map(function(value, key) {
        return _.defaults({ name: key }, value);
    })
    .sortBy('name')
    .value();
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 */


    },
    doLogin(){
       
      if (this.nameLogin === "" || this.passwordLogin === "") {
        this.emptyFields = true;
      } else {
        this.busy = true
        var postData = {
          "action":"login",
          "username":this.nameLogin,
          "password":this.passwordLogin
        }
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
            },
          'withCredentials': true
        }
        this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
                .then((response) => {
                  this.error_login_msg=response.data.message
                  if(response.data.success === true){
                    this.username = response.data.username
                    this.token = response.data.tokenphp
                    this.init()
                  }else{
                    this.busy = false
                  }
                })
                .catch(error => {
                    console.error('There was an error!', error)
                }); 
      }      
    },
    logout(){
      var postData = {
        "action":"logout",
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            this.username = null
            this.init()
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },
    getorders(rn){
      if(rn == null) rn=(this.salepoint.rn != null)?this.salepoint.rn:null;
      var postData = {
        "action":"getorders"
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            if(response.data.success != 'false'){
              this.orders = response.data.orders
              this.checks = response.data.checks
              this.amount = 0
              this.selectedTopay = []

              this.$bvModal.show('orders-modal')

              //console.log('orders')
              //console.log(this.orders)
              //console.log('checks')
              //console.log(this.checks)
            }else{
              if(response.data.error=="auth"){
                this.needlogin = true
              }else if(response.data.error=="tokenout"){
                this.needlogin = true
              }
            }
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },
    /*
    cleartoken(){
      var postData = {
        "action":"cleartoken",
      }
      var axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
          },
          'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            this.username = null
            this.init()
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },
*/
/*
    makeToast(){
     
        this.$toasted.show('Toast show', {
          icon: 'check',
          action : {
              text : 'Cancel',
              onClick : (e, toastObject) => {
                          toastObject.goAway(0);
                         }
          },


        })
    },
*/    
    addCart(rn){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      this.$axios.post('/', {"action":"addcart","rn":rn}, axiosConfig )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != "false"){
                        this.cart = response.data.basket
                        this.cartItems = response.data.item
                        this.cart_count = parseInt(response.data.summary.sum_count)
                        this.cart_sum = parseFloat(response.data.summary.sum_price)
                        this.cart.forEach((vale) => {
                          (this.dishes[vale.menu_rn]).quant_cart = vale.quant;
                        })
                    }else{
                      if(response.data.error=="auth"){
                        this.needlogin = true
                      }else if(response.data.error=="tokenout"){
                        this.needlogin = true
                      }else if(response.data.message != null){
                        this.$toasted.error(response.data.message).goAway(3000)
                      } 
                    }
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
    },
    clearCart(){
      this.cart.forEach((vale) => {
                      (this.dishes[vale.menu_rn]).quant_cart = 0
                    })
      this.cart = []
      this.cartItems = []
      this.cart_count = 0
      this.cart_sum = 0
    },
    setCartQuant(rn, val){
      this.$axios.post('/', {"action":"setcartquant","rn":rn,"quant":val}, { withCredentials: true } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      (this.dishes[rn]).quant_cart = val
                      this.cart = response.data.basket
                      this.cartItems = response.data.item
                      this.cart_count = parseInt(response.data.summary.sum_count)
                      this.cart_sum = parseFloat(response.data.summary.sum_price)
                      this.cart.forEach((vale) => {
                        (this.dishes[vale.menu_rn]).quant_cart = vale.quant
                      })
                     }else{
                      if(response.data.error=="auth"){
                        this.needlogin = true
                      }else if(response.data.error=="tokenout"){
                        this.needlogin = true
                      }else if(response.data.message != null){
                        this.$toasted.error(response.data.message).goAway(3000)
                      }  
                    }  
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
    },
    reservOrder(mode) {
      this.$axios.post('/', {"action":"saveorder","mode":mode} )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      if(response.data.message === 'saveordermode'){
                        console.log(this.showMsgBoxOrderSaveMode())
                        return
                      }
                      console.log('Save order')
                      console.log(response.data)
                      if(response.data.success === true){
                        this.$toasted.success(response.data.message).goAway(3000)
                        this.$bvModal.hide('basket-modal2')
                        this.clearCart()
                        this.init_order()                      
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000)
                      }  
                    }else{
                      if(response.data.error=="auth"){
                        this.needlogin = true
                      }else if(response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 

                      }
                    }    
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
      
    },
    showMsgBoxOrderSaveMode() {
        this.orderSaveMode = ''
        this.$bvModal.msgBoxConfirm('На цей час вже маєте неоплачене замовлення', {
          title: 'Зробіть вибір',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Замінити',
          cancelVariant: 'success',
          cancelTitle: 'Додати',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value === true) {
              return this.reservOrder('replace')
            }else if(value === false) {
              return this.reservOrder('append')              
            }
          })
          .catch(err => {
            // An error occurred
            console.log('Save order '+err)
          })
    },
    removeOrder(rn,confirm) {
      if(!confirm){
        this.showMsgBoxOrderRemoveConfirm(rn)
        return
      }
      if(confirm === 'false'){
        return
      }
      this.ordersbusy = true
      this.$axios.post('/', {"action":"removeorder","rn":rn } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      this.$toasted.success(response.data.message).goAway(3000)
                      this.init_zakazy()
                      this.selectedTopay = this.selectedTopay.filter(item => item != rn)
                      this.recalcSumToPay()
                      /*
                      this.$nextTick(() => { //должно успеть перечитать
                        this.reloadSelectedToPay()
                      })
                      */
                      this.ordersbusy = false
                    }else{
                      this.ordersbusy = false
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                      }
                    }  
                  })
                  .catch(error => {
                    this.ordersbusy = false
                    console.error('There was an error!', error)
                  });
      
    },
    showMsgBoxOrderRemoveConfirm(rn) {
        this.$bvModal.msgBoxConfirm('Ви дійсно бажаєте видалити замовлення?', {
          title: 'Підтвердження',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Видалити',
          cancelVariant: 'success',
          cancelTitle: 'Відміна',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value === true) {
              return this.removeOrder(rn,'true')
            }else if(value === false) {
              return 
            }
          })
          .catch(err => {
            // An error occurred
            console.log('Save order '+err)
          })
    },
    PayClubCard(rns,confirm) {
      if(!confirm){
        this.showMsgBoxPayClubCardConfirm(rns)
        return
      }
      if(confirm === 'false'){
        return
      }

      this.ordersbusy = true
      this.$axios.post('/', {"action":"payordersbyclubcard","rn":rns } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      this.$toasted.success(response.data.message).goAway(3000)
                      this.zakazy = {}
                      this.selectedTopay=[]
                      this.recalcSumToPay()
                      this.init_zakazy_afterpay()
                      this.ordersbusy = false
                      this.select_pay_method_visible = false
                    }else{
                      this.ordersbusy = false
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                      }
                    }  
                  })
                  .catch(error => {
                    this.ordersbusy = false
                    console.error('There was an error!', error)
                  });





    },  
    showMsgBoxPayClubCardConfirm(rns) {

        if((this.clubcards[0]).balance + (this.clubcards[0]).cardlimit < this.amount){
          this.$bvModal.msgBoxOk('На вашому рахунку не вистачає коштів для оплати', {
              title: 'Попередження',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'bruvis',
              okTitle: 'Ok',
              footerClass: 'p-2',
              hideHeaderClose: true,
              centered: true,
              headerClass: 'bg-danger text-white border-danger',
            })
            .then(value => {
              console.log(value)
              return
            })
          return
        }


        this.$bvModal.msgBoxConfirm('Ви дійсно бажаєте оплатити замовлення клубною карткою?', {
          title: 'Підтвердження',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Оплатити',
          cancelVariant: 'bruvis',
          cancelTitle: 'Відміна',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value === true) {
              return this.PayClubCard(rns,'true')
            }else if(value === false) {
              return 
            }
          })
          .catch(err => {
            // An error occurred
            console.log('Pay order '+err)
          })
    },
    recalcSumToPay(){
      var sum = 0;
      this.selectedTopay.forEach((itm) => {
        switch (itm.charAt(0)) {
          case 'c':
            sum += (this.checks[itm.substring(1)]).sumToPay
            break
          case 'o':  
            sum += (this.orders[itm.substring(1)]).sumToPay
            break
        }
      })
      this.amount = Math.round(sum * 100) / 100
    },
    selectAll(){
      let zkday,z
      this.selectedTopay = []
      this.allSelected = !this.allSelected
      if (this.allSelected) {
        for (zkday in this.zakazy) {
          for (z in this.zakazy[zkday]){
            //console.log(this.zakazy[zkday][z])
            if(new Date() < new Date(this.zakazy[zkday][z].deadln)){
              this.selectedTopay.push(this.zakazy[zkday][z].rn)
            }  
          }  
        }
      }
      this.recalcSumToPay()
    },
    selectAllOff(){
      this.allSelected = false
      this.recalcSumToPay()
    },
    /*
    reloadSelectedToPay(){
      let zkday,z
      this.selectedTopay = []
      for (zkday in this.zakazy) {
        for (z in this.zakazy[zkday]){
          this.selectedTopay.push(this.zakazy[zkday][z].rn)
        }  
      }
      this.recalcSumToPay()
    },*/
    payOrders(){
      console.log(this.selectedTopay)
      if(this.selectedTopay.length == 0) return false

      //this.salepoint.clubCardPayment = true
      //this.salepoint.creditCardPayment = false

      if(this.salepoint.clubCardPayment && this.salepoint.creditCardPayment){
        if(this.clubcards.length === 0){
          return this.PayCreditCard(this.selectedTopay.join())  
        }else{
          this.select_pay_method_visible = true
          return false
        }  
      }else if(this.salepoint.clubCardPayment){
        if(this.clubcards.length === 0){
          this.$toasted.error('Помилка: жодної клубної карти не закріплено за користувачем').goAway(5000)
        }else{
          return this.PayClubCard(this.selectedTopay.join())    
        }
      }else if(this.salepoint.creditCardPayment){
        return this.PayCreditCard(this.selectedTopay.join())
      }else{
        this.$toasted.error('Помилка конфігурації: не задано метод платежу').goAway(3000)
      }      
      /*
      this.globalbusy = true

      this.$axios.post('/', {"action":"payorders","rn":this.selectedTopay.join()} )
                  .then((response) => {

                    if(response.data.success != 'false'){                    
                      this.globalbusy = false
                      //eslint-disable-next-line
                      LiqPayCheckout.init({
                        "data": response.data.data,
                        "signature": response.data.signature,
                        "embedTo": "#liqpay_checkout",
                        "language": "uk",
                        "mode": "embed" // embed || popup
                      }).on("liqpay.callback", function(data){
                          console.log('Callback')
                          console.log(data.status)
                          console.log(data)

                            let getStatusInt = setInterval(function(){
                              this.$axios.post('/', {"action":"getpaystatus","rn":this.selectedTopay.join()} )
                                  .then((response) => {
                                      let result = response.data
                                      switch(result.status){
                                          case 'wait':
                                            console.log('wait')
                                            break
                                          case 'success':
                                            console.log('success')
                                            clearInterval(getStatusInt)
                                            this.$toasted.success(result.message).goAway(3000)              
                                            this.onSuccessPay()
                                            break
                                          case 'ready':
                                            console.log(result.data)
                                            clearInterval(getStatusInt)
                                            this.$toasted.warning(result.message).goAway(3000)
                                            break
                                          default:
                                            console.log(result)  
                                        } 

                                  })
                                  .catch(error => {
                                    console.error('There was an error!', error)
                                  });
                            }.bind(this), 2000);
                        }.bind(this)
                      ).on("liqpay.ready", function(data){

                        console.log(data.status)
                        this.widget_is_visible = true

                        }.bind(this)
                      ).on("liqpay.close", function(data){
                          // close
                          console.log('Close')
                          console.log(data)
                      });
                    }else{
                      if(response.data.error=="auth"){
                        this.needlogin = true
                      }else if(response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 

                      }
                    }  
                  })
                  .catch(error => {
                    this.globalbusy = false
                    console.error('There was an error!', error)
                  });
                  */
    },
    /*
    getPayStatus(){
      this.$axios.post('/', {"action":"getpaystatus","rn":this.selectedTopay.join()} )
                  .then((response) => {
                    return response.data
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });

    },
    */
    PayCreditCard(rns){
      this.globalbusy = true
      this.$axios.post('/', {"action":"payorders","rn":rns} )
                  .then((response) => {

                    if(response.data.success != 'false'){                    
                      this.globalbusy = false
                      //eslint-disable-next-line
                      LiqPayCheckout.init({
                        "data": response.data.data,
                        "signature": response.data.signature,
                        "embedTo": "#liqpay_checkout",
                        "language": "uk",
                        "mode": "embed" // embed || popup
                      }).on("liqpay.callback", function(data){
                          console.log('Callback')
                          console.log(data.status)
                          console.log(data)

                            let getStatusInt = setInterval(function(){
                              this.$axios.post('/', {"action":"getpaystatus","rn":rns} )
                                  .then((response) => {
                                      let result = response.data
                                      switch(result.status){
                                          case 'wait':
                                            console.log('wait')
                                            break
                                          case 'success':
                                            console.log('success')
                                            clearInterval(getStatusInt)
                                            this.$toasted.success(result.message).goAway(3000)              
                                            this.onSuccessPay()
                                            break
                                          case 'ready':
                                            console.log(result.data)
                                            clearInterval(getStatusInt)
                                            this.$toasted.warning(result.message).goAway(3000)
                                            break
                                          default:
                                            console.log(result)  
                                        } 

                                  })
                                  .catch(error => {
                                    console.error('There was an error!', error)
                                  });
                            }.bind(this), 2000);
                        }.bind(this)
                      ).on("liqpay.ready", function(data){

                        console.log(data.status)
                        this.widget_is_visible = true
                        }.bind(this)
                      ).on("liqpay.close", function(data){
                          // close
                          console.log('Close')
                          console.log(data)
                      });
                    }else{
                      if(response.data.error=="auth"){
                        this.needlogin = true
                      }else if(response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 

                      }
                    }  
                  })
                  .catch(error => {
                    this.globalbusy = false
                    console.error('There was an error!', error)
                  });
    },

    onSuccessPay(){
      this.init_order() 
      this.selectedTopay = []
      /*
      console.log('Before')
      console.log(this.checks)
      console.log(this.orders)
      this.selectedTopay.forEach((itm) => {
        switch (itm.charAt(0)) {
          case 'c':
            delete this.checks[itm.substring(1)]
            console.log('delete c '+itm)
            break
          case 'o': 
            delete this.orders[itm.substring(1)] 
            console.log('delete o '+itm)
            break
        }
      })
      this.selectedTopay = []
      console.log('After')
      console.log(this.checks)
      console.log(this.orders)
      */
    },
  /*  getMenu2(){
       this.$axios.post('/', {"action":"getmenu","rn":"005VV"} )
                  .then((response) => {
                    this.posts  = response.data
                    this.dishes = this.posts.dishes
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
    },*/
    changeSalePoint(rn){
      this.init(rn,null)
    },
    changeMealtime(rn){
      this.init(null,null,rn)
    },
    changeOndate(rn){
      this.init(null,rn)
    },
    setNextDay(){
      if(this.ondateTabIndex < this.ondates.length-1){
        this.init(null,this.ondates[this.ondateTabIndex+1])
      }
    },
    setPrevDay(){
      if(this.ondateTabIndex > 0){
        this.init(null,this.ondates[this.ondateTabIndex-1])
      }
    },
    setCategory(rn){
      this.categories = []
      this.categories = this.post.categories.filter(item => item.rn==rn)
    },
    isEmptyCategory(ct){
      var ret = true
      ct.childs.forEach((vale) => {
        Object.keys(this.dishes).forEach((vali) => {
          if(this.dishes[vali].rp === vale){
            ret = false
          }
        })
      })
      return ret
    },

        //this.$toasted.success('success').goAway(2000)
        //this.$toasted.error('error').goAway(3000)
        //this.$toasted.info('info').goAway(5000)

    dateDisabled(ymd, date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        // disable days that fall on the 13th of the month
        //console.log(date)
        // const weekday = date.getDay()
        var dt = '' + date.getFullYear()
        dt += (date.getMonth()<9)?'-0':'-'
        dt += date.getMonth()+1
        dt += (date.getDate()<10)?'-0':'-' 
        dt += date.getDate()
        //console.log(dt)
        //console.log((this.holidays[dt]!=null)?'true':'false')
        //const day = date.getDate()
        // Return `true` if the date should be disabled
        //return weekday === 0 || weekday === 6 || day === 13
        //return weekday === 0
        //return this.holidays[dt] != null
        return !this.ondates.includes(dt)
    },
    scrollIntoView(href) {
      const el = href ? document.querySelector(href) : null
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop
      }
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    showDish(rn){
      this.target_dish = rn
      this.$bvModal.show('dish-target-modal')
    },
    //************************************************ 
    clickLeft() {
      this.navElement.scrollLeft -= 50;
      this.$refs.scrollLeft.style.left = this.navElement.scrollLeft + "px";
      this.$refs.scrollRight.style.right = -this.navElement.scrollLeft + "px";

      this.$refs.scrollLeft.disabled = (this.$refs.scrollLeft.style.left === '0px') 
    },
    clickRight() {
      this.navElement.scrollLeft += 50;
      this.$refs.scrollRight.style.right = -this.navElement.scrollLeft + "px";
      this.$refs.scrollLeft.style.left = this.navElement.scrollLeft + "px";

      this.$refs.scrollLeft.disabled = (this.$refs.scrollLeft.style.left === '0px')

    },
/*
    onTouchStart(event) {
      this.startX = event.touches[0].pageX - this.navElement.offsetLeft;
      this.scrollLeft = this.navElement.scrollLeft;
    },
    onTouchMove(event) {
      event.preventDefault();
      const x = event.touches[0].pageX - this.navElement.offsetLeft;
      const speed = 1; // higher number means faster scrolling
      const walk = (x - this.startX) * speed;
      this.navElement.scrollLeft = this.scrollLeft - walk;
    },
    onMouseDown(event) {
      this.isMouseDown = true;

      this.startX = event.pageX - this.navElement.offsetLeft;
      this.scrollLeft = this.navElement.scrollLeft;
    },
    onMouseUp() {
      this.isMouseDown = false;
    },
    onMouseLeave() {
      this.isMouseDown = false;
    },
    onMouseMove(event) {
      if (!this.isMouseDown) return;

      event.preventDefault();
      const x = event.pageX - this.navElement.offsetLeft;
      const speed = 1.5; // higher number means faster scrolling
      const walk = (x - this.startX) * speed;
      this.navElement.scrollLeft = this.scrollLeft - walk;
      this.$refs.scrollLeft.style.left = this.navElement.scrollLeft + "px";
      this.$refs.scrollRight.style.right = -this.navElement.scrollLeft + "px";
    },
*/

    //*************************************************
    /*
    showPayWindow(rn) {
 
      if(this.ordfood.length + this.checkbase.length > 0){

        if(this.ordfood.length > 0 && !this.ordfood[0].paytime) {
          this.globalbusy = true
          rn = this.ordfood[0].rn
          //if(this.checkbase.length > 0) rn = this.checkbase[0].rn
          this.$axios.post('/', {"action":"payreservedorder","rn":rn} )
                  .then((response) => {
                    if(response.data.success != 'false'){ 
                      console.log(response.data)
                      this.globalbusy = false
                      //this.$bvModal.show('orders-modal')            
                      //eslint-disable-next-line
                      LiqPayCheckout.init({
                        "data": response.data.data,
                        "signature": response.data.signature,
                        "embedTo": "#liqpay_checkout",
                        "language": "uk",
                        "mode": "popup" // embed || popup
                      }).on("liqpay.callback", function(data){
                          console.log('Callback')
                          console.log(data.status)
                          console.log(data)
                      }).on("liqpay.ready", function(data){
                          // ready
                          console.log('Ready')
                          console.log(data)
                      }).on("liqpay.close", function(data){
                          // close
                          console.log('Close')
                          console.log(data)
                      });
                    }else{
                      if(response.data.error=="auth"){
                        this.needlogin = true
                      }else if(response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 

                      }
                    }  
                  })
                  .catch(error => {
                    this.globalbusy = false
                    console.error('There was an error!', error)
                  });
          }else{
            // no unpayed order
          }
      }else{
        // no unpayed
      }





      
                  
    }
  */
    

  }
   
}
</script>

<style scope>
  #appc {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    padding-top: 80px;
  }
  .quant {
    min-width: 16px;
    height: 16px;
    position: absolute;
/*    top: 10px;
    right: 0px;*/
    top: 6px;
    left: 16px;
    background: #d00;
    border-radius: 10px;
    font-family: "SegoeUISemiBold",sans-serif;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
  }
  .add_cart_item{
    position:absolute;
    bottom:0px;
    right:31px;
    padding:0;
    border:0;
  }
  .item_quant {
    min-width: 16px;
    height: 16px;
    position: absolute;
    bottom: 25px;
    right: -6px;
    background: rgb(5, 128, 32);
    border-radius: 10px;
    font-family: "SegoeUISemiBold",sans-serif;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
    text-align: center;
  }
  .button-cart {
    position:relative;
  }
  .error {
   /*animation-name: errorShake;
   animation-duration: 0.3s;*/
  }

  @keyframes errorShake {
    0% {
        transform: translateX(-25px);
    }
    25% {
        transform: translateX(25px);
    }
    50% {
        transform: translateX(-25px);
    }
    75% {
        transform: translateX(25px);
    }
    100% {
        transform: translateX(0);
    }
  }
  .ico-remove-item {
    position: absolute;
    top:-2px;
    right: 14px;
  }
  .dish_smarks{
    position: absolute;
    top:-3px;
    left: 10px;
  }
  .mark_new {
    /*
    position: absolute;
    height: 50px;
    top: -13px;
    left: 0;
    */
    background-color: blue;
    color: white;
  }
  .mark_sale {
    /*
    position: absolute;
    height: 40px;    
    top:1px;
    left:0;
    */
    background-color: magenta;
    color: white;
  }
  .mark_vegan {
    /*
    position: absolute;
    height: 40px;    
    top:2px;
    left:0;
    */
    background-color: green;
    color: white;
  }
  .mark_18+ {
    /*
    position: absolute;
    height: 40px;    
    top:3px;
    left:0;
    */
    background-color: black;
    color: white;
  }
  .mark_spicy{
    /*
    position: absolute;
    height: 40px;    
    top:0;
    left:0;
    */
    background-color: red;
    color: white;
  }
  /**/
.navbar-nav .dropdown-menu {
    position: absolute;
}

.discounted{
  text-decoration: line-through;
  text-decoration-color: #d00;
  position: relative;
  top: -10px;
  color: #999;
}

.orders-specs-row{
  background-color:#dcdcdc;
}
.orders-specs-row .td-active{
  cursor: pointer;
}
.order-failed td{
  text-decoration: line-through;
  cursor:not-allowed;
}


.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.custom-select > option:disabled{
  color:#ccc;
}

.min-vh-70 {
    min-height: 70vh!important;
}

.orders-menu-spec{
  font-size: 15px;
  font-weight: 400;
}

.orders-table, .orders-table td{
  border-width: 0;
  border-spacing: 0px;
}


/*=============================================================== */



/*
.nav-tabs {
  display: none;
}

@media (min-width: 768px) {
  .nav-tabs {
    display: flex;
    flex-flow: column nowrap;
  }
  .nav-tabs {
    border-bottom: none;
    border-right: 1px solid #ddd;
    display: flex;
  }
  .nav-tabs {
    margin: 0 15px;
  }
  .nav-tabs .nav-item + .nav-item {
    margin-top: 0.25rem;
  }
  .nav-tabs .nav-link {
    transition: border-color 0.125s ease-in;
    white-space: nowrap;
  }
  .nav-tabs .nav-link:hover {
    background-color: #f7f7f7;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active {
    border-bottom-color: #ddd;
    border-right-color: #fff;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    margin-right: -1px;
  }
  .nav-tabs .nav-link.active:hover {
    background-color: #fff;
    border-color: #0275d8 #fff #0275d8 #0275d8;
  }

  .card {
    border: none;
  }

  .card .card-header {
    display: none;
  }

  .card .collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  .tab-pane {
    display: block !important;
    opacity: 1;
  }
}
*/

/*-------------------------------------------*/


  
</style>
