<template>
    <div class="archive">
        <b-row>
            <b-col xl="4" lg="4" md="5">
                <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner
                    spinner-variant="bruvis"
                >
                    <v-calendar
                        is-expanded
                        :first-day-of-week="2"
                        ref="calendar" 
                        masks="YYYY-MM-DD" 
                        :locale="{ id: 'uk', firstDayOfWeek: 2, masks: { weekdays: 'WW' } }"
                        :attributes.sync = "attrs"
                        :max-page="{year: (new Date()).getFullYear(), month: (new Date()).getMonth()+1}"
                        :available-dates.sync="availDates"
                        :highlight ="true"
                        :rows="$screens({ default: 1, xl: 2, lg: 2})"
                        @dayclick="dayClicked"
                        style="margin:0 auto"
                    />
                </b-overlay>    
            </b-col>
            <b-col xl="8" lg="8" md="7">
                    <b-alert show variant="light" class="text-center" v-if="dt_id">
                        <b-icon v-if="dt_index > 0" @click="gotoDateIndex(dt_index-1)" icon="chevron-left" class="mr-2" role="button"/>
                        {{new Date(dt_id) | dateFormat('dddd D MMMM YYYY')}}
                        <b-icon v-if="dt_index < days_payed.length-1" @click="gotoDateIndex(dt_index+1)" icon="chevron-right" class="ml-2" role="button"/>

                    </b-alert>
                    <template v-if="zakazy_day != null">
                        <div class="perforated_check mx-auto" v-for="(ch,index2) of zakazy_day" :key="index2">
                          <svg width="200px" viewBox="0 0 280 5" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0,0 280,0" fill="none" stroke="#e2e3de" stroke-dasharray="3 3" stroke-width="20"/>
                          </svg>
                          <table class="teble mx-auto w-100">
                            <tr :key="index2">
                              <th colspan=2 class="text-center pb-3 pt-3">
                                Рахунок № {{ch.checknum}}<br>
                                {{(mealtimes[ch.mealtime]).mnemo}} {{new Date(ch.date) | dateFormat('DD.MM.YYYY')}}
                                <template v-if="ch.canceled"><div class="shtamp-canceled">Анульовано!</div></template>
                              </th>
                            </tr>
                            <tr v-for="(sp,index3) in ch.specs" :key="index3">
                              <td class="text-left pr-3 pl-1">{{sp.menu}}<template v-if="sp.quant>1"> (x{{sp.quant}})</template></td>
                              <td class="text-right text-nowrap pl-3 pr-1"><template v-if="sp.quant>1">{{sp.quant}} x </template>{{sp.price | toCurrency}}</td>
                            </tr>
                            <tr class="bordered-top" :key="index2" v-if="ch.totalDiscountSum > 0">
                              <td class="text-left pr-3 pl-1">Знижка</td>
                              <td class="text-right pl-3 pr-1">- {{ch.totalDiscountSum | toCurrency}}</td>
                            </tr>
                            <tr :key="index2">
                              <th class="text-left pr-3 pl-1">Разом сплачено</th>
                              <th class="text-right pl-3 pr-1">{{ch.sumToPay | toCurrency}}</th>
                            </tr>
                            <tr :key="index2" v-if="ch.canceled">
                              <th class="text-left pr-3 pl-1 comment-canceled">Повернено на баланс клубної карти</th>
                              <th class="text-right pl-3 pr-1 comment-canceled">{{ch.sumToPay | toCurrency}}</th>
                            </tr>
                          </table>  
                        </div>
                    </template>
            </b-col>
        </b-row>    

            <!--div v-if="dt && dt.id && zakazy_payed[dt.id]">
                <pre>{{zakazy_payed[dt.id]}}</pre>
            </div-->    

<!--  date-picker   v-model = "dt"
:attributes ="{ highlight:{color: 'blue',fillMode: 'light'},color: 'red'}"

-->
        <!--div>{{dt}}</div>
        <div><pre>{{days_payed}}</pre></div>
        <div><pre>{{zakazy_payed}}</pre></div-->



    </div>
</template>

<script>

export default {
  name: 'ArchivZakazov',
  props: {
    mealtimes:{
        type: Object,
        required: true
    }
  },
  data: () => ({
    zakazy_payed : {}, 
    days_payed : [],
    dt: null,
    dt_label: null,
    dt_index:null,
    dt_id:null,
    zakazy_day: null,
    busy: null
  }),  
  methods: {
        getArchiveMonth(m, y){
            this.busy = true;
            this.$axios.post('/', {"action":"getarchive","m":m,"y":y } )
                .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){
                    this.zakazy_payed = response.data.zakazy_payed
                    this.days_payed = response.data.days_payed
                    this.gotoDateIndex(this.days_payed.length-1)
                    this.busy = false;

                    //const calendar = this.$refs.calendar
                    //calendar.attributes =  this.getAttributes()

                    //console.log(this.getAttributes())
                    //console.log(calendar)

                    //this.$refs.calendar.attributes.available-dates = this.archive.days_payed
                    //this.$toasted.success(response.data.message).goAway(3000)
                    //this.ordersbusy = false
                }else{

                    this.busy = false;
                    if(response.data.error=="auth" || response.data.error=="tokenout"){
                        //this.needlogin = true
                        this.$emit('needlogin')
                    }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                    }
                }  
                })
                .catch(error => {
                    this.busy = false
                    console.error('There was an error!', error)
                });
        },
        dayClicked(day){
            if(day.isDisabled) return false
            this.dt = day
            this.zakazy_day = this.zakazy_payed[day.id]
            this.dt_index = this.days_payed.indexOf(day.id)
            this.dt_label = day.ariaLabel
            this.dt_id = day.id
            //console.log(day)
        },
        gotoDateIndex(idx){
            this.dt_id = this.days_payed[idx]
            this.zakazy_day = this.zakazy_payed[this.dt_id]
            this.dt_index = idx
            //this.dt_label = this.days_payed[idx].dateFormat('dddd D MMMM')

        },
        getAttributes(){
            return this.days_payed.map( d => ({
                    dates: new Date(d),
                    highlight:{
                        color: 'blue',
                        fillMode: 'light'
                    },
                    color: 'red'
                }))
                
            
        }
    
  },
  
  mounted() {
      this.getArchiveMonth()
  },

    computed: {
        availDates(){
            return this.days_payed.map(d => new Date(d))
        },
        attrs(){
            //return this.days_payed.map(d => ({dates: new Date(d), highlight: true}))
            return this.days_payed.map(d => ({
                dates: new Date(d), 
                highlight: {
                    color: 'gray',
                    fillMode: 'solid'
                }
            }))
        },
        /*
        minPage(){
            return {
                min-page [
                    
                        year: (new Date(this.zakazy_days[0])).getFullYear(), 
                        month: (new Date(this.zakazy_days[0])).getMonth()
                        
                ]
            }    
        }
        */
        
    }
}
</script>

<style scoped>

.perforated_check{
  position: relative;
  margin: 3px;
  width: 400px;
  min-height: 200px;
  background: #e2e3de;
  padding: 4px 20px;
  box-shadow: 10px 10px 20px rgb(0 0 0 / 20%);
  font-family: courier;
}

.perforated_check svg{
  position: absolute;
  top: -3px;
  left: 2px;
  width: 100%;
}

.perforated_check table td{
  border-bottom: 1px dashed #c5c5c5;
  vertical-align: top;
}

.perforated_check table {
  margin-bottom:20px;
}

.perforated_check:hover svg.ico-del-zakaz{
  visibility: visible;
  opacity: 1;
}

.shtamp-canceled{
  color: #b9333f;
  background-color: transparent;
  border: 3px solid #b9333f;
  border-radius: 0.25rem;
  position: absolute;
  padding: 0.75rem 1.25rem;
  transform: rotate(45deg);
  opacity: 0.7;
}

.comment-canceled{
  color: #b9333f;
  padding-top: 0.5em;
} 

.archive{
    /*
    display: flex;
    justify-content: space-evenly;
    */
}


@media (max-width: 575px) {
  .perforated_check{
    width: 100%;
  }
  .card-body {
    padding:0;
  }

}

@media (hover: none) {


}    



</style>
