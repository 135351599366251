<template>
              <div v-if="Object.keys(zakazy_payed).length > 0">
                <b-form-select
                  v-model="zakazy_payed_index"
                  class="d-block d-lg-none"
                >
                  <template v-for="(dt,index) of  Object.keys(zakazy_payed)">
                    <b-form-select-option 
                      :key="index" 
                      :value="index"
                    >  
                      {{new Date(dt) | dateFormat('dddd D MMMM')}}
                    </b-form-select-option>
                  </template>
                </b-form-select>

                <b-card no-body>
                  <b-tabs 
                    small 
                    pills 
                    card 
                    vertical 
                    v-model="zakazy_payed_index"
                    nav-wrapper-class="d-none d-lg-block"
                    active-nav-item-class = "bg-bruvis text-white"
                  >
                    <b-tab v-for="(dt,index) of  Object.keys(zakazy_payed)" :title="new Date(dt) | dateFormat('dddd D MMMM')" :key="index" class="text-center">
                      <template v-if="zakazy_payed[dt] != null">
                        <div class="perforated_check mx-auto" v-for="(ch,index2) of zakazy_payed[dt]" :key="index2">
                          <svg width="200px" viewBox="0 0 300 5" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0,0 300,0" fill="none" stroke="#e2e3de" stroke-dasharray="3 3" stroke-width="20"/>
                          </svg>
                          <b-icon
                            v-if="!ch.canceled" 
                            icon="x-circle-fill" 
                            class="ico-del-zakaz "
                            variant="danger"
                            font-scale="1.3"
                            v-b-popover.hover.topright="'Анулювати замовлення'"
                            role="button"
                            @click="annulOrder(ch.rn)"
                          ></b-icon>
                          <table class="teble mx-auto w-100">
                            <tr :key="index2">
                              <th colspan=2 class="text-center pb-3 pt-3">
                                Рахунок № {{ch.checknum}}<br>
                                {{(mealtimes[ch.mealtime]).mnemo}} {{new Date(dt) | dateFormat('DD.MM.YYYY')}}
                                <template v-if="ch.canceled"><div class="shtamp-canceled">Анульовано!</div></template>
                              </th>
                            </tr>
                            <tr v-for="(sp,index3) in ch.specs" :key="index3">
                              <td class="text-left pr-3 pl-1">{{sp.menu}}<template v-if="sp.quant>1"> (x{{sp.quant}})</template></td>
                              <td class="text-right text-nowrap pl-3 pr-1"><template v-if="sp.quant>1">{{sp.quant}} x </template>{{sp.price | toCurrency}}</td>
                            </tr>
                            <tr class="bordered-top" :key="index2" v-if="ch.totalDiscountSum > 0">
                              <td class="text-left pr-3 pl-1">Знижка</td>
                              <td class="text-right pl-3 pr-1 text-nowrap">- {{ch.totalDiscountSum | toCurrency}}</td>
                            </tr>
                            <tr :key="index2">
                              <th class="text-left pr-3 pl-1">Разом сплачено</th>
                              <th class="text-right pl-3 pr-1">{{ch.sumToPay | toCurrency}}</th>
                            </tr>
                            <tr :key="index2" v-if="ch.canceled">
                              <th class="text-left pr-3 pl-1 comment-canceled">Повернено на баланс клубної карти</th>
                              <th class="text-right pl-3 pr-1 comment-canceled">{{ch.sumToPay | toCurrency}}</th>
                            </tr>
                          </table>  
                        </div>
                      </template>
                    </b-tab>
                  </b-tabs>
                </b-card>  
              </div>
              <div v-else class="text-center">
                <h5>Замовлень немає</h5>
              </div>
</template>

<script>
export default {
  name: 'ZakazyPayed',
  props: {
    zakazy_payed: {
      type: Object,
      required: true
    },
    mealtimes:{
      type: Object,
      required: true
    }
  },
  data: () => ({
      zakazy_payed_index: 0
  }),
  methods:{

    showMsgBoxOrderAnnulConfirm(rn) {
      //**************

      this.$axios.post('/', {"action":"preannulorder","rn":rn } )
        .then((response) => {
          if(response.data.success != false && response.data.success != 'false'){
            //  аннулирование возможно
            this.$bvModal.msgBoxConfirm('При анулюванні оплаченого замовлення кошти зараховуються на баланс клубної карти. Ви дійсно бажаєте продовжити?', {
                        title: 'Підтвердження',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Анулювати',
                        cancelVariant: 'success',
                        cancelTitle: 'Відмінити',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                      })
                      .then(value => {
                            if(value === true) {
                              return this.annulOrder(rn,'true')
                            }else if(value === false) {
                              return 
                            }
                      })
                      .catch(err => {
                        console.log('Annul order '+err)
                      })
          }else{
            if(response.data.error=="auth" || response.data.error=="tokenout"){
              this.$emit('needlogin')
            }else{
              this.$bvModal.msgBoxOk(response.data.message, {
                title: 'Попередження',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'bruvis',
                okTitle: 'Ok',
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true,
                headerClass: 'bg-danger text-white border-danger',
              })
              .then(value => {
                console.log(value)
                return
              })
            }
          }  
        })
        .catch(error => {
          this.ordersbusy = false
          console.error('There was an error!', error)
        });



      //**************************
      /*
      this.$bvModal.msgBoxConfirm('Ви дійсно бажаєте анулювати замовлення?', {
        title: 'Підтвердження',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Анулювати',
        cancelVariant: 'success',
        cancelTitle: 'Відміна',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
            if(value === true) {
              return this.annulOrder(rn,'true')
            }else if(value === false) {
              return 
            }
      })
      .catch(err => {
        console.log('Annul order '+err)
      })
      */
    },

    annulOrder(rn,confirm) {
      if(!confirm){
        this.showMsgBoxOrderAnnulConfirm(rn)
        return
      }
      if(confirm === 'false'){
        return
      }
      //this.$emit('globalbusy')
      //this.ordersbusy = true
      this.$axios.post('/', {"action":"annulorder","rn":rn } )
        .then((response) => {
          if(response.data.success != false && response.data.success != 'false'){
            this.$toasted.success(response.data.message).goAway(3000)
            this.$emit('ondelete')
            //this.ordersbusy = false
          }else{
            //this.ordersbusy = false
            if(response.data.error=="auth" || response.data.error=="tokenout"){
              //this.needlogin = true
              this.$emit('needlogin')
            }else{
              this.$toasted.error(response.data.message).goAway(3000) 
            }
          }  
        })
        .catch(error => {
          this.ordersbusy = false
          console.error('There was an error!', error)
        });
      
    }



  }
}
</script>

<style scoped>

.check{
  padding: 5px;
  background:
    linear-gradient(-45deg, #0000 6px, #fff 7px),
    linear-gradient( 45deg, #0000 6px, #fff 7px),
    linear-gradient(-45deg, #fff calc(100% - 7px), #0000 calc(100% - 8px)),
    linear-gradient( 45deg, #fff calc(100% - 7px), #0000 calc(100% - 8px));
  background-size: 10px 50%;
  background-position: 0 100%, 0 100%, 0 0, 0 0;
  background-repeat: repeat-x;
  font-family: courier;
}

.perforated_check{
  position: relative;
  margin: 3px;
  width: 400px;
  min-height: 200px;
  background: #e2e3de;
  padding: 4px 20px;
  box-shadow: 10px 10px 20px rgb(0 0 0 / 20%);
  font-family: courier;
}

.perforated_check svg{
  position: absolute;
  top: -3px;
  left: 2px;
  width: 100%;
}

.perforated_check table td{
  border-bottom: 1px dashed #c5c5c5;
  vertical-align: top;
}

.perforated_check table {
  margin-bottom:20px;
}

svg.ico-del-zakaz{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  top: 23px;
  left: 21px;
  width: 30px;
}  

.perforated_check:hover svg.ico-del-zakaz{
  visibility: visible;
  opacity: 1;
}

.shtamp-canceled{
  color: #b9333f;
  background-color: transparent;
  border: 3px solid #b9333f;
  border-radius: 0.25rem;
  position: absolute;
  padding: 0.75rem 1.25rem;
  transform: rotate(45deg);
  opacity: 0.7;
}

.comment-canceled{
  color: #b9333f;
  padding-top: 0.5em;
}  


@media (max-width: 575px) {
  .perforated_check{
    width: 100%;
/*    min-height: 200px;*/
  }
  .card-body {
    padding:0;
  }

}

@media (hover: none) {
  svg.ico-del-zakaz{
    visibility: visible;
    opacity: 1;
  }    

}    


</style>
